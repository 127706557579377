import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { map } from 'rxjs';

import {
	PermissionsStoreService,
	AccessDeniedService,
} from '@emrm/core/permissions/service';

export const PermissionAppGuard: CanActivateFn = (
	route: ActivatedRouteSnapshot,
) => {
	const router: Router = inject(Router);
	const permissionsStoreService: PermissionsStoreService = inject(
		PermissionsStoreService,
	);
	const accessDeniedService: AccessDeniedService = inject(AccessDeniedService);
	const appCode = route.data['appCode'];

	return permissionsStoreService.getPermissionAppWithAccess$(appCode).pipe(
		map((response) => {
			if (!response.granted) {
				if (response.appPermission) {
					accessDeniedService.redirectFromModule(response.appPermission.name);
				}

				router.navigate(['/403']);
			}

			return response.granted;
		}),
	);
};
